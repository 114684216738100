<template>
    <div>
        <h1>All Projects</h1>

        <!-- Show loading spinner if projects are loading -->
        <div v-if="loading" class="spinner">Loading projects...</div>

        <!-- Show "No projects" message if no projects are loaded -->
        <h3 v-if="!loading && projects.length === 0">No projects</h3>

        <SortAndFilter class="sortAndFilter" />

        <!-- Render the projects when they are loaded -->
        <Project v-for="singleProject in projects" :key="singleProject.id" :project="singleProject" :mode="'display'"
            @donate="openModal(singleProject)" />

        <!-- Modal component -->
        <DonateModal v-if="isModalOpen" :show="isModalOpen" @close="closeModal" :project="selectedProject" />
    </div>
</template>

<script>
import axios from 'axios';
import Project from './Project.vue';
import DonateModal from './DonateModal.vue'; // Import the modal component
import SortAndFilter from './SortAndFilter.vue';
import { getCustomHeaderName, getCustomHeaderValue } from '@/utils/utils';

export default {
    name: "AllProjects",
    components: {
        Project,
        DonateModal,
        SortAndFilter
    },
    data() {
        return {
            projects: [],
            isModalOpen: false,
            selectedProject: null, // To hold the project data for the modal
            loading: true, // Track the loading state
        }
    },
    methods: {
        async getProjects() {
            this.loading = true; // Start loading
            try {
                const apiUrl = import.meta.env.VITE_API_URL;
                let projectsUrl = apiUrl + `/Projects`;
                let headerName = getCustomHeaderName();
                let headerValue = getCustomHeaderValue();
                console.log('Projects url: ' + projectsUrl);
                let response = await axios.get(projectsUrl, {
                    headers: {
                        [headerName]: headerValue
                    }
                });
                this.projects = response.data.map(element => ({
                    id: element.id,
                    catId: element.categoryId,
                    name: element.name,
                    author: element.author,
                    bioUrl: element.bioUrl,
                    description: element.description,
                    walletAddress: element.walletAddress,
                    imageUrl: element.imageUrl,
                    imageData: element.imageData ? "data:image/jpg;base64, " + element.imageData : "",
                    created: element.created,
                    numberOfDonations: element.numberOfDonations,
                    donationsReceived: element.donationsReceived
                }));
            } catch (error) {
                console.log('Error Getting Projects: ' + error);
            } finally {
                this.loading = false; // End loading regardless of success or failure
            }
        },
        openModal(project) {
            console.log('Donate clicked on project: ' + project.id);
            this.selectedProject = project; // Set the selected project
            this.isModalOpen = true; // Open the modal
        },
        closeModal() {
            this.isModalOpen = false; // Close the modal
            this.selectedProject = null; // Clear the selected project
        }
    },
    mounted() {
        this.getProjects();
    }
}
</script>

<style scoped>
.spinner {
    text-align: center;
    font-size: 18px;
    padding: 20px;
}

.spinner::after {
    content: '';
    display: inline-block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 4px solid transparent;
    border-top: 4px solid #3498db;
    animation: spin 1s linear infinite;
}

.sortAndFilter {
    margin-bottom: 50px;
}

/* Spinner animation */
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>