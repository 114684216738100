<template>
    <div>
        <!-- Hamburger Menu Button -->
        <button @click="toggleMenu" class="hamburger-menu">
            &#9776; <!-- This is the hamburger icon (3 horizontal lines) -->
        </button>

        <!-- Filters Section -->
        <div v-if="isMenuVisible" class="filters-container">
            <input type="text" v-model="sortAndFilter.projectName" placeholder="Project Name">
            <input type="text" v-model="sortAndFilter.creatorName" placeholder="Creator Name">
            <input type="text" v-model="sortAndFilter.description" placeholder="Project Name">
            <select v-model="sortAndFilter.category">
                <option value="" selected>Select Category</option>
                <option v-for="cat in categories" :key="cat.id" :value="cat.id">{{ cat.name }}</option>
            </select>
            <select v-model="sortAndFilter.sortBy">
                <option value="" selected>Sort By</option>
                <option v-for="cat in sortBy" :key="cat.id" :value="cat.id">{{ cat.name }}</option>
            </select>
            <input type="button" value="Reset" @click="resetClicked" />
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { getCustomHeaderName, getCustomHeaderValue } from '@/utils/utils';

export default {
    name: "SortAndFilter",
    data() {
        return {
            // Control visibility of the menu
            isMenuVisible: false,
            sortAndFilter: {
                projectName: '',
                creatorName: '',
                description: '',
                category: '',
                sortBy: '',
            },
            categories: [],
            sortBy: [{ id: 0, name: 'Created Date' }, { id: 1, name: 'Name' }]
        };
    },
    methods: {
        resetClicked() {
            console.log('reset filters clicked');
            this.sortAndFilter.projectName = '';
            this.sortAndFilter.creatorName = '';
            this.sortAndFilter.description = '';
            this.sortAndFilter.category = '';
            this.sortAndFilter.sortBy = '';
        },
        toggleMenu() {
            this.isMenuVisible = !this.isMenuVisible;
        }
    },
    async mounted() {
        try {
            const apiUrl = import.meta.env.VITE_API_URL;
            let headerName = getCustomHeaderName();
            let headerValue = getCustomHeaderValue();
            console.log("Get categories apiUrl: " + apiUrl);
            let response = await axios.get(apiUrl + '/Categories/', {
                headers: { [headerName]: headerValue }
            });
            console.log('Get categories response: ' + response);
            this.categories = response.data;
        } catch (error) {
            console.log('Error getting project categories: ' + error);
        }
    }
}
</script>

<style scoped>
/* Basic styles for the hamburger menu and filter container */
.hamburger-menu {
    font-size: 30px;
    cursor: pointer;
    background: none;
    border: none;
}

.filters-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

/* Hide the filters section on small screens by default */
@media screen and (max-width: 768px) {
    .filters-container {
        display: none;
    }

    .hamburger-menu {
        display: block;
    }
}

/* When menu is visible, show the filters */
@media screen and (max-width: 768px) {
    .filters-container.show {
        display: block;
    }
}
</style>