<template>
    <div class="contact-us">
        <h2>Contact Us</h2>

        <form @submit.prevent="submitContactForm" class="contact-form">
            <div class="form-group">
                <label for="name">Name</label>
                <input type="text" id="name" v-model="name" required />
            </div>

            <div class="form-group">
                <label for="email">Email</label>
                <input type="email" id="email" v-model="email" required />
            </div>

            <div class="form-group">
                <label for="message">Message</label>
                <textarea id="message" v-model="message" rows="4" required></textarea>
            </div>

            <div class="form-actions">
                <button type="submit" class="submit-btn">Submit</button>
            </div>
        </form>
    </div>
</template>

<script>
import axios from 'axios';
import { getCustomHeaderName, getCustomHeaderValue } from '@/utils/utils';

export default {
    name: "ContactUs",
    data() {
        return {
            name: '',
            email: '',
            message: '',
            recaptchaResponse: ''
        }
    },
    methods: {
        // Function to handle form submission
        async submitContactForm() {
            try {
                const apiUrl = import.meta.env.VITE_API_URL;
                const contactUrl = `${apiUrl}/Contact/ContactUs`;
                const headerName = getCustomHeaderName();
                const headerValue = getCustomHeaderValue();

                // Prepare form data
                const contactUsForm = {
                    name: this.name,
                    email: this.email,
                    message: this.message,
                };

                // Send POST request
                const response = await axios.post(contactUrl, contactUsForm, {
                    headers: {
                        [headerName]: headerValue
                    }
                });

                console.log('Contact form submitted successfully:', response.data);
                alert('Thank you for your message! We will get back to you soon.');
                // Clear form fields after successful submission
                this.name = '';
                this.email = '';
                this.message = '';
            } catch (error) {
                console.error('Error submitting contact form:', error);
                alert('There was an error submitting your form. Please try again later.');
            }
        }
    },
}
</script>

<style scoped>
.contact-us {
    width: 100%;
    /* Take up full width of the container */
    max-width: 1200px;
    /* Optional: Set a max-width for very large screens */
    margin: 0 auto;
    /* Centers the form horizontally */
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

h2 {
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
}

.contact-form {
    display: flex;
    flex-direction: column;
    gap: 16px;
    /* Adds space between form fields */
}

.form-group {
    display: flex;
    flex-direction: column;
    gap: 8px;
    /* Adds space between label and input/textarea */
}

label {
    font-weight: bold;
    display: block;
}

input[type="text"],
input[type="email"],
textarea {
    width: 100%;
    /* Ensure inputs and textarea take up full width */
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
}

textarea {
    resize: vertical;
}

.form-actions {
    display: flex;
    justify-content: center;
}

.submit-btn {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.submit-btn:hover {
    background-color: #0056b3;
}

/* Optional: For smaller screens */
@media (max-width: 768px) {
    .contact-us {
        padding: 15px;
        /* Adjust padding on smaller screens */
    }
}
</style>