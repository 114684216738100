<template>
    <div>
        <div class="addDiv add-card" v-if="mode === 'add'">
            <h2>This project will be linked to your Algorand account: {{ formatAddPageWalletAddress }}</h2>
            <input class="required" v-model="newProject.name" placeholder="Project Name" />
            <input class="required" v-model="newProject.author" placeholder="Creator Name" />
            <input v-model="newProject.bioUrl" placeholder="Bio URL" />
            <input class="required" v-model="newProject.description" placeholder="Project Description" />
            <input class="required" v-model="newProject.imageUrl" placeholder="Image URL" />
            <select class="required" v-model="newProject.category">
                <option value="" disabled selected>Select Category</option>
                <option v-for="cat in categories" :key="cat.id" :value="cat.id">{{ cat.name }}</option>
            </select>
            <input class="donate" type="button" value="Add Project" @click="validateAndAddProject" />
            <p v-if="errorMessage" class="error">{{ errorMessage }}</p>
            <p v-if="successMessage" class="success">{{ successMessage }}</p>
        </div>

        <div v-else class="project-card">
            <img v-if="project.imageUrl" :src="project.imageUrl" class="project-image" />
            <img v-if="project.imageData" :src="project.imageData" class="project-image" />
            <div class="project-info">
                <h3 class="project-name">{{ project.name }}</h3>
                <p class="project-description">{{ project.description }}</p>
                <p class="project-description">Creator: {{ project.author }}</p>
                <p class="project-description">Creator's Link: <a target="_blank" :href="project.bioUrl">{{
                    project.bioUrl }}</a></p>
                <p class="project-wallet">Wallet: {{ formatWalletAddress }}</p>
                <p class="project-wallet">Date Added: {{ getLocalDateString(project.created) }}</p>
                <p class="project-wallet">Number of Donations Received: {{ project.numberOfDonations }}</p>
                <p class="project-wallet">Total Donation Amount: {{ project.donationsReceived }}</p>
            </div>
            <!-- Emit event to parent -->
            <input v-if="mode == 'display'" class="donate" type="button" value="Donate"
                @click="$emit('donate', project)" />
            <input v-else class="donate" type="button" value="Delete" @click="isModalOpen = true" />
            <ConfirmDialog :isOpen="isModalOpen" @close="isModalOpen = false" @confirm="deleteProject" />
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import ConfirmDialog from './ConfirmDialog.vue';
import { getCustomHeaderName, getCustomHeaderValue } from '@/utils/utils';

export default {
    name: "SingleProject",
    props: {
        project: {
            type: Object,
            default: () => ({})
        },
        mode: {
            type: String,
            required: true
        }
    },
    components: {
        ConfirmDialog
    },
    data() {
        return {
            isModalOpen: false,
            newProject: {
                id: '',
                name: '',
                author: '',
                bioUrl: '',
                description: '',
                walletAddress: '',
                imageUrl: '',
                imageData: '',
                category: '',
                created: '',
                numberOfDonations: '',
                donationsReceived: '',
            },
            categories: [],
            errorMessage: '', // To store error messages
            successMessage: '',
        };
    },
    computed: {
        ...mapGetters(['getAccountAddress']), // Map the getter here
        ...mapGetters(['getToken']),
        getLocalDateString() {
            return (originalDate) => {
                // Ensure the originalDate is in ISO 8601 string format (or valid date string)
                const date = new Date(originalDate);

                // Check if the date is valid
                if (isNaN(date.getTime())) {
                    // Return a fallback message or empty string if the date is invalid
                    return 'Invalid Date';
                }

                // Return the date formatted as a local date string
                return date.toLocaleString();
            };
        },
        formatWalletAddress() {
            return (
                this.project.walletAddress.substring(0, 6) +
                "..." +
                this.project.walletAddress.substring(this.project.walletAddress.length - 6, this.project.walletAddress.length)
            );
        },
        formatAddPageWalletAddress() {
            return (
                this.getAccountAddress.substring(0, 6) +
                "..." +
                this.getAccountAddress.substring(this.getAccountAddress.length - 6, this.getAccountAddress.length)
            );
        }
    },
    methods: {
        validateAndAddProject() {
            this.errorMessage = ''; // Reset the error message
            this.successMessage = '';

            // Basic validation
            this.newProject.walletAddress = this.getAccountAddress;
            if (!this.newProject.name || !this.newProject.author || !this.newProject.description || !this.newProject.imageUrl || !this.newProject.category) {
                this.errorMessage = 'Please fill in all fields.';
                return; // Stop if validation fails
            }

            this.addProject();
        },
        async addProject() {
            try {
                const apiUrl = import.meta.env.VITE_API_URL;
                console.log("Add Project apiUrl: " + apiUrl);
                console.log("Add Project data: " + JSON.stringify(this.newProject));
                const token = this.getToken;
                console.log('My Projects token: ' + token);
                if (token) {
                    let headerName = getCustomHeaderName();
                    let headerValue = getCustomHeaderValue();
                    let response = await axios.post(apiUrl + '/Projects/', this.newProject, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            WalletAddress: this.getAccountAddress,
                            [headerName]: headerValue
                        }
                    });
                    console.log("Add project response: " + response);
                    // Clear the new project data
                    this.newProject = { name: '', description: '', walletAddress: '', imageUrl: '', category: '', imageData: '', id: '', author: '', bioUrl: '', created: '', numberOfDonations: '', donationsReceived: '' };
                    this.successMessage = 'Project added successfully';
                }
            } catch (error) {
                console.log('Error adding project: ', error);

                // Check if error.response exists and if it's a BadRequest (status 400)
                if (error.response) {
                    // Extract the message from the error response body
                    if (error.response.status === 400 && error.response.data) {
                        this.errorMessage = error.response.data; // This assumes the error message is in the response body.
                    } else {
                        // Fallback error message if no specific error message exists in the response
                        this.errorMessage = 'Error adding project: ' + error.response.statusText;
                    }
                } else {
                    // If no response was received (e.g., network issues)
                    this.errorMessage = 'Network error or no response from server.';
                }
            }
        },
        async deleteProject() {
            try {
                this.errorMessage = '';
                console.log('Delete clicked on project id: ' + this.project.id);
                const apiUrl = import.meta.env.VITE_API_URL;
                let headerName = getCustomHeaderName();
                let headerValue = getCustomHeaderValue();
                console.log("Delete Project apiUrl: " + apiUrl);
                const token = this.getToken;
                console.log('Delete Project token: ' + token);
                if (token) {
                    let response = await axios.delete(apiUrl + `/Projects/Project/${this.project.id}`, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            WalletAddress: this.getAccountAddress,
                            [headerName]: headerValue
                        }
                    });
                    console.log("delete project response: " + response);
                    // Clear the new project data
                    this.successMessage = 'Project deleted';
                    this.$emit('projectDeleted', this.project.id); // Emit an event if needed
                }
            } catch (error) {
                console.log('Error deleteing project: ' + error);
                this.errorMessage = 'Error deleting project.';
            }
        }
    },
    async mounted() {
        if (this.mode === 'add') {
            try {
                const apiUrl = import.meta.env.VITE_API_URL;
                let headerName = getCustomHeaderName();
                let headerValue = getCustomHeaderValue();
                console.log("Get categories apiUrl: " + apiUrl);
                let response = await axios.get(apiUrl + '/Categories/', {
                    headers:
                        { [headerName]: headerValue }
                });
                console.log('Get categories response: ' + response);
                this.categories = response.data;
            } catch (error) {
                console.log('Error getting project categories: ' + error);
            }
        }
    }
}
</script>

<style scoped>
.required {
    border-color: red;
}

.error {
    color: red;
    font-size: 1em;
}

.success {
    color: green;
    font-size: 1em;
}

.project-card:hover {
    transform: scale(1.1);
}

.project-image {
    width: 100%;
    /* Ensures the image takes the full width of the container */
    height: 100%;
    /* Ensures the image takes the full height of the container */
    border-radius: 8px;
    /* Keeps the border radius for rounded corners */
    object-fit: contain;
    /* Make sure the entire image fits within the container */
    max-width: 100%;
    /* Prevents the image from exceeding container width */
    max-height: 200px;
    /* Adjust this value to your desired height */
    display: block;
    /* Ensures that there's no extra space below the image */
}

.project-info {
    margin-top: 12px;
}

.project-name {
    font-size: 1.5em;
    margin: 0;
}

.project-description {
    color: #555;
}

.project-wallet {
    color: #777;
    font-size: 0.9em;
}

.donate {
    background-color: var(--color-heading);
    /* Use your theme color */
    color: var(--vt-c-white);
    /* Text color for contrast */
    border: none;
    border-radius: 4px;
    /* Slightly less than card for a neat look */
    padding: 10px 16px;
    /* Padding for a comfortable size */
    cursor: pointer;
    /* Indicate clickable */
    font-size: 1em;
    /* Adjust size as needed */
    transition: background-color 0.3s, transform 0.2s;
    margin-top: 12px;
    /* Space between button and text */
}

.donate:hover {
    background-color: var(--vt-c-indigo);
    /* Darker shade on hover */
    transform: scale(1.05);
    /* Slight scale effect */
}

.addDiv {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    /* Adds space between the inputs */
}

.add-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 16px;
    margin: 0;
    /* Reset margin */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #fff;
}

.addDiv input {
    flex: 1 1 calc(50% - 16px);
    /* Makes each input take up half the width with a gap between them */
    min-width: 200px;
    /* Prevents the inputs from becoming too narrow on small screens */
    padding: 8px;
    box-sizing: border-box;
}

/* Adjust for smaller screens */
@media (max-width: 600px) {
    .addDiv input {
        flex: 1 1 100%;
        /* Make inputs take full width on small screens */
    }
}
</style>