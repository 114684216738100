<template>
    <div class="about-us">
        <h2>About Us</h2>
        <p>Algo.Vote is a place where people can share what they created. As long as their creations relate to the
            Algorand Cryptocurrency/Blockchain in some way, it can be added.</p>
        <p>Your creation doesn't have to be a huge project that took years to make. It can be something as simple as
            a meme, video, or audio clip.</p>
        <p>We encourage everyone to submit what they created. This site is just another way to get your creations
            exposed to the public.</p>
        <p>No accounts or passwords needed. Just connect your Perawallet using the button at the top of the page, and
            you are ready to go.</p>
        <p>Add creations and donate some algo—let's help make Algorand a fun ecosystem to be in!</p>

        <!-- Optional call to action button -->
        <div class="cta">
            <button @click="gotoProjects">Check out the showcase</button>
        </div>
    </div>
</template>

<script>
export default {
    name: "AboutUs",
    methods: {
        gotoProjects() {
            // This method can navigate the user to a specific page (e.g., project creation page)
            console.log("Redirect to create page");
            // Example:
            this.$router.push('/Projects'); // Replace with the actual path to your creation page
        }
    }
}
</script>

<style scoped>
.about-us {
    max-width: 800px;
    margin: 0 auto;
    padding: 40px;
    background-color: #f4f4f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    font-family: 'Arial', sans-serif;
}

h2 {
    font-size: 2rem;
    color: #333;
    margin-bottom: 20px;
    font-weight: bold;
}

p {
    font-size: 1rem;
    color: #555;
    line-height: 1.6;
    margin-bottom: 16px;
}

.cta button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 12px 24px;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 20px;
}

.cta button:hover {
    background-color: #0056b3;
}

@media (max-width: 768px) {
    .about-us {
        padding: 20px;
    }

    h2 {
        font-size: 1.5rem;
    }

    p {
        font-size: 0.95rem;
    }
}
</style>
