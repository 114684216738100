import { createRouter, createWebHistory } from "vue-router";
import Projects from "@/components/Projects.vue";
import Project from "@/components/Project.vue";
import MyProjects from "@/components/MyProjects.vue";
import ContactUs from "@/components/ContactUs.vue";
import AboutUs from "@/components/AboutUs.vue";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: "/",
      component: Projects,
    },
    {
      path: "/Projects",
      component: Projects,
    },
    {
      path: "/MyProjects/:wallet",
      component: MyProjects,
    },
    {
      path: "/AddProject/:mode",
      component: Project,
      props: true, // This will pass route params as props
    },
    {
      path: "/ContactUs",
      component: ContactUs,
    },
    {
      path: "/AboutUs",
      component: AboutUs,
    },
  ],
});

export default router;
